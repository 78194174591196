import React, { useState, useEffect } from "react";
import { Typography, Grid, Button, Box, TextField } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import socket from "../../../../socket/Socket.js";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { set } from "zod";

const ChartTypesPage = ({ onHide }) => {
	const [user, setUser] = useState(null);
	const [initiated, setInitiated] = useState(false);
	const [accounts, setAccounts] = useState([]);
	const [selectedOption, setSelectedOption] = useState(null);
	const [showPaymentInputs, setShowPaymentInputs] = useState(false);
	const [selectedPurchaseOption, setSelectedPurchaseOption] = useState(null);
	const [options, setOptions] = useState([]);
	const [cardDetails, setCardDetails] = useState({
		number: "",
		name: "",
		expiry: "",
		cvc: "",
		focused: "",
	});

	useEffect(() => {
		getChartTypes();
		const storedUser = JSON.parse(localStorage.getItem("user"));
		setUser(storedUser);
		const storedSelectedOption = localStorage.getItem("chartType");
		if (storedSelectedOption) {
			setSelectedOption(storedSelectedOption);
		}
	}, []);

	const getChartTypes = (userDrawings) => {
		axios
			.post("https://mitserver.app:9005/getList", { table: "chartTypes" })
			.then((response) => {
				console.log("CHART RESP", response.data);
				setOptions(response.data);
			})
			.catch((error) => {
				console.error("Error loading drawings:", error);
			});
	};

	const handlePayment = (option) => {
		setShowPaymentInputs(true);
		setSelectedPurchaseOption(option);
	};

	const handleSelectOption = (option) => {
		setSelectedOption(option);
		localStorage.setItem("chartType", option);
		toast.success(`Seleccionado ${option}`, {
			autoClose: 2000,
			theme: "dark",
		});
		onHide(false);
	};

	const isPaid = (option) => {
		return user && user.paidOptions && user.paidOptions.includes(option);
	};

	const handleInputChange = (e) => {
		setCardDetails({ ...cardDetails, [e.target.name]: e.target.value });
	};

	const handleInputFocus = (e) => {
		setCardDetails({ ...cardDetails, focused: e.target.name });
	};

	const handleSubmitPayment = () => {
		const payload = {
			userId: user.id,
			amount: selectedPurchaseOption.price,
			chartType: selectedPurchaseOption.key,
			cardDetails: cardDetails,
		};
		axios
			.post("https://mitserver.app:9005/purchase", payload)
			.then((response) => {
				if (response.status === 200) {
					toast.success("Pago realizado con éxito", {
						autoClose: 2000,
						theme: "dark",
					});

					// Actualizar el localStorage del usuario
					const storedUser = JSON.parse(localStorage.getItem("user"));
					if (storedUser) {
						let paidOptions = [];
						if (storedUser.paidOptions) {
							paidOptions = JSON.parse(storedUser.paidOptions);
						}
						paidOptions.push(selectedPurchaseOption.key);
						storedUser.paidOptions = JSON.stringify(paidOptions);
						localStorage.setItem("user", JSON.stringify(storedUser));
					}
					handleSelectOption(selectedPurchaseOption.key);
					setShowPaymentInputs(false);
				}
			})
			.catch((error) => {
				toast.error("Error en el pago", {
					autoClose: 2000,
					theme: "dark",
				});
			});
	};

	return (
		<div style={{ backgroundColor: "#131722" }}>
			<Grid container spacing={2} style={{ marginTop: 30 }}>
				{options.map((option) => {
					const paid = isPaid(option.key);
					const isSelected = selectedOption === option.key;
					return (
						<Grid item xs={4} key={option.id}>
							<div
								className="option-container"
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
									maxWidth: 345,
									background: paid ? "rgba(0, 0, 0, 0.6)" : "rgba(128, 128, 128, 0.6)",
									borderRadius: "20px",
									padding: "20px",
									boxShadow: paid
										? "0 0 5px #4599d9, 0 0 10px #4599d9, 0 0 15px #4599d9"
										: "0 0 5px #808080, 0 0 10px #808080, 0 0 15px #808080",
									transition: "all 0.3s ease",
								}}>
								<p
									className="option-description"
									style={{
										fontWeight: 800,
										color: "#fff",
										// textShadow: "0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff",
										marginBottom: "15px",
										textAlign: "center",
									}}>
									{option.title}
								</p>
								{paid ? (
									<Button
										onClick={() => handleSelectOption(option.key)}
										sx={{
											backgroundColor: isSelected ? "#4599d9" : "transparent",
											color: "#fff",
											border: `2px solid ${isSelected ? "#4599d9" : "#f74712"}`,
											borderRadius: "12px",
											fontWeight: "bold",
											// padding: "8px 16px",
											fontSize: "0.7rem",
											cursor: "pointer",
											transition: "all 0.3s ease",
											// textShadow: "0 0 5px #fff",
											boxShadow: isSelected ? "0 0 5px #4599d9, 0 0 10px #4599d9" : "0 0 5px #f74712, 0 0 10px #f74712",
											"&:hover": {
												backgroundColor: isSelected ? "#3d87c3" : "rgba(247, 71, 18, 0.2)",
												boxShadow: "0 0 5px #4599d9, 0 0 10px #4599d9",
											},
										}}>
										{isSelected ? "Seleccionado" : "Seleccionar"}
									</Button>
								) : (
									<Button
										onClick={() => handlePayment(option)}
										sx={{
											backgroundColor: "transparent",
											color: "#fff",
											border: "2px solid #f74712",
											borderRadius: "12px",
											fontWeight: "bold",
											fontSize: "0.7rem",
											padding: "6px 20px",
											cursor: "pointer",
											transition: "all 0.3s ease",
											textShadow: "0 0 2px #fff",
											boxShadow: "0 0 10px #f74712, 0 0 20px #f74712",
											"&:hover": {
												backgroundColor: "rgba(247, 71, 18, 0.2)",
												boxShadow: "0 0 15px #4599d9, 0 0 30px #4599d9",
											},
										}}>
										Comprar
									</Button>
								)}
							</div>
						</Grid>
					);
				})}
			</Grid>

			{showPaymentInputs && (
				<Box
					sx={{
						marginTop: 4,
						padding: 2,
						backgroundColor: "#1c2331",
						borderRadius: "10px",
						boxShadow: "0 0 5px #4599d9, 0 0 10px #4599d9",
					}}>
					<Typography sx={{ fontSize: 12, p: 1, textAlign: "center" }} color="text.secondary" gutterBottom>
						Suscripción Mensual de <span style={{ fontWeight: "bold", color: "white" }}>{selectedPurchaseOption.key}</span> por $
						<span style={{ fontWeight: "bold", color: "white" }}>{selectedPurchaseOption.price}</span>
					</Typography>
					<Cards
						number={cardDetails.number}
						name={cardDetails.name}
						expiry={cardDetails.expiry}
						cvc={cardDetails.cvc}
						focused={cardDetails.focused}
					/>
					<TextField
						label="Número de Tarjeta"
						variant="outlined"
						fullWidth
						margin="dense"
						name="number"
						value={cardDetails.number}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						InputProps={{
							style: { color: "#fff", fontSize: "0.8rem" },
						}}
						InputLabelProps={{
							style: { color: "#fff", fontSize: "0.8rem" },
						}}
					/>
					<TextField
						label="Nombre en la Tarjeta"
						variant="outlined"
						fullWidth
						margin="dense"
						name="name"
						value={cardDetails.name}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						InputProps={{
							style: { color: "#fff", fontSize: "0.8rem" },
						}}
						InputLabelProps={{
							style: { color: "#fff", fontSize: "0.8rem" },
						}}
					/>
					<TextField
						label="Fecha de Expiración"
						variant="outlined"
						fullWidth
						margin="dense"
						name="expiry"
						value={cardDetails.expiry}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						InputProps={{
							style: { color: "#fff", fontSize: "0.8rem" },
						}}
						InputLabelProps={{
							style: { color: "#fff", fontSize: "0.8rem" },
						}}
					/>
					<TextField
						label="CVV"
						variant="outlined"
						fullWidth
						margin="dense"
						name="cvc"
						value={cardDetails.cvc}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						InputProps={{
							style: { color: "#fff", fontSize: "0.8rem" },
						}}
						InputLabelProps={{
							style: { color: "#fff", fontSize: "0.8rem" },
						}}
					/>
					<Button
						variant="contained"
						color="primary"
						fullWidth
						onClick={handleSubmitPayment}
						sx={{
							marginTop: 2,
							backgroundColor: "#4599d9",
							color: "#fff",
							fontWeight: "bold",
							padding: "8px 16px",
							fontSize: "0.8rem",
							cursor: "pointer",
							transition: "all 0.3s ease",
							textShadow: "0 0 10px #fff",
							boxShadow: "0 0 5px #4599d9, 0 0 10px #4599d9",
							"&:hover": {
								backgroundColor: "#3d87c3",
								boxShadow: "0 0 5px #4599d9, 0 0 10px #4599d9",
							},
						}}>
						Pagar
					</Button>
				</Box>
			)}
		</div>
	);
};

export default ChartTypesPage;
