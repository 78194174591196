import React, { useEffect, useState, useRef, useCallback } from "react";
import { Box, CircularProgress, Typography, TextField } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { store } from "../../../../store/store.js";
import { toast } from "react-toastify";
import { set } from "zod";

export function HistoryPositionsTable() {
	const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
	const derivWS = store((state) => state.derivWS);
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(true);
	const [totals, setTotals] = useState({ profit: 0, wins: 0, losses: 0, totalGain: 0, totalLoss: 0 });
	const [offset, setOffset] = useState(0); // Para manejar la paginación
	const [startDate, setStartDate] = useState(null); // Fecha de inicio
	const [endDate, setEndDate] = useState(null); // Fecha de fin
	const containerRef = useRef(null);

	useEffect(() => {
		findHistoryPositions({ startDate, endDate });
		getSocketMessages();
	}, []);

	const findHistoryPositions = async ({ startDate, endDate }) => {
		if (!startDate) {
			const sevenDaysAgo = new Date();
			sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
			startDate ? (startDate = new Date(startDate).toISOString().split("T")[0]) : (startDate = sevenDaysAgo.toISOString().split("T")[0]);
		}
		if (!endDate) {
			endDate ? (endDate = new Date(endDate).toISOString().split("T")[0]) : (endDate = new Date().toISOString().split("T")[0]);
		}

		setStartDate(startDate);
		setEndDate(endDate);
		setLoading(true);

		try {
			const dates = {
				profit_table: 1,
				description: 1,
				sort: "DESC",
				limit: 500,
				date_from: startDate,
				date_to: endDate,
			};

			console.log("DATES", dates);
			await derivWS.send(JSON.stringify(dates));
		} catch (error) {
			console.error("Error loading history positions:", error);
		}
	};

	const getSocketMessages = () => {
		derivWS.onmessage = function (msg) {
			const msgData = JSON.parse(msg.data);
			const msgType = msgData.msg_type;

			if (msgType === "profit_table") {
				const transactions = msgData.profit_table?.transactions;
				console.log("TRANSACTIONS", transactions);
				if (!transactions || transactions.length === 0) {
					console.warn("No transactions found for the selected dates.");
					setRows([]); // Limpiamos las filas si no hay transacciones
					setTotals({ profit: 0, wins: 0, losses: 0, totalGain: 0, totalLoss: 0 });
					setLoading(false);
					return;
				}

				let totalProfit = 0;
				let totalWins = 0;
				let totalLosses = 0;
				let totalGain = 0;
				let totalLoss = 0;

				const transformedRows = transactions.map((transaction) => {
					const date = new Date(transaction.purchase_time * 1000);
					const formattedDate =
						date.toLocaleDateString("es-ES") +
						" " +
						date.toLocaleTimeString("es-ES", {
							hour: "2-digit",
							minute: "2-digit",
						});

					const profit = transaction.sell_price - transaction.buy_price;
					const status = profit < 0 ? "Perdida" : "Ganada";
					const profitColor = profit < 0 ? "red" : "#4599d9";

					totalProfit += profit;
					if (profit < 0) {
						totalLosses++;
						totalLoss += profit;
					} else {
						totalWins++;
						totalGain += profit;
					}

					console.log("HISTORY POSITIONS", transaction);
					// Usamos un generador de ID único para la clave
					return {
						id: `${transaction.contract_id}-${Math.random()}`, // Generamos un ID único
						date: formattedDate,
						symbol: transaction.underlying_symbol,
						contractId: transaction.contract_id,
						type: transaction.contract_type,
						amount: transaction.buy_price,
						profit: profit.toFixed(2),
						status: status,
						profitColor: profitColor,
					};
				});

				setRows((prevRows) => [...prevRows, ...transformedRows]); // Añadir más filas sin sobrescribir
				setTotals({
					profit: totalProfit.toFixed(2),
					wins: totalWins,
					losses: totalLosses,
					totalGain: totalGain.toFixed(2),
					totalLoss: Math.abs(totalLoss).toFixed(2),
				});
				setLoading(false);
			}
		};
	};

	const handleScroll = useCallback(() => {
		if (containerRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
			if (scrollTop + clientHeight >= scrollHeight) {
				setOffset((prevOffset) => prevOffset + 25); // Cargar más datos al final
			}
		}
	}, []);

	useEffect(() => {
		if (containerRef.current) {
			containerRef.current.addEventListener("scroll", handleScroll);
			return () => containerRef.current.removeEventListener("scroll", handleScroll);
		}
	}, [handleScroll]);

	if (loading && rows.length === 0) {
		return (
			<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
				<CircularProgress />
			</Box>
		);
	}

	return (
		<Box sx={{ width: "100%", backgroundColor: "#161a25", padding: 1, position: "relative" }}>
			{/* Inputs de fecha nativos */}
			<Box sx={{ position: "absolute", top: 8, right: 16, zIndex: 9999, display: "flex", gap: 2 }}>
				<TextField
					type="date"
					label="Fecha inicio"
					value={startDate || ""}
					onChange={(e) => findHistoryPositions({ startDate: e.target.value, endDate })}
					sx={{
						backgroundColor: "rgba(255, 255, 255, 0.2)",
						borderRadius: "8px",
						height: "30px", // Altura ajustada
						width: "100px", // Reducción del width
						"& .MuiInputBase-input": {
							fontSize: "12px", // Tamaño del texto más pequeño
							padding: "4px 8px", // Padding más reducido
						},
						"& .MuiInputLabel-root": {
							fontSize: "12px", // Tamaño del texto del label más pequeño
						},
						"& .MuiSvgIcon-root": {
							color: "white", // Cambiar el color del ícono a blanco
						},
					}}
					InputLabelProps={{
						shrink: true,
					}}
					InputProps={{
						style: {
							height: "30px", // Ajuste del tamaño interno
						},
					}}
				/>
				<TextField
					type="date"
					label="Fecha fin"
					value={endDate || ""}
					onChange={(e) => findHistoryPositions({ startDate, endDate: e.target.value })}
					sx={{
						backgroundColor: "rgba(255, 255, 255, 0.2)",
						borderRadius: "8px",
						height: "30px", // Altura ajustada
						width: "100px", // Reducción del width
						"& .MuiInputBase-input": {
							fontSize: "12px", // Tamaño del texto más pequeño
							padding: "4px 8px", // Padding más reducido
						},
						"& .MuiInputLabel-root": {
							fontSize: "12px", // Tamaño del texto del label más pequeño
						},
						"& .MuiSvgIcon-root": {
							color: "white", // Cambiar el color del ícono a blanco
						},
					}}
					InputLabelProps={{
						shrink: true,
					}}
					InputProps={{
						style: {
							height: "30px", // Ajuste del tamaño interno
						},
					}}
				/>
			</Box>

			{/* Títulos de columnas */}
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					backgroundColor: "rgba(69, 153, 217, 0.2)",
					padding: "8px 15px",
					borderRadius: "8px",
					color: "#eaeaea",
				}}>
				<Box sx={{ flex: 1 }}>Fecha</Box>
				<Box sx={{ flex: 1 }}>Símbolo</Box>
				<Box sx={{ flex: 1 }}>ID Contrato</Box>
				<Box sx={{ flex: 1 }}>Tipo</Box>
				<Box sx={{ flex: 1 }}>Monto</Box>
				<Box sx={{ flex: 1 }}>Profit</Box>
				<Box sx={{ flex: 1 }}>Status</Box>
			</Box>

			{/* Contenedor con scroll para filas */}
			<Box
				ref={containerRef}
				sx={{
					maxHeight: "400px",
					overflowY: "auto",
					padding: "15px",
				}}>
				{rows.map((row) => (
					<Box
						key={row.id}
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							backgroundColor: "rgba(23, 27, 38, 0.9)",
							padding: "6px 15px",
							borderRadius: "8px",
							boxShadow: "0px 3px 5px rgba(69, 153, 217, 0.3)",
							color: "#fff",
							cursor: "pointer",
							marginBottom: "6px",
							transition: "background-color 0.3s ease",
							"&:hover": {
								backgroundColor: "rgba(69, 153, 217, 0.2)",
							},
						}}>
						<Box sx={{ flex: 1 }}>{row.date}</Box>
						<Box sx={{ flex: 1 }}>{row.symbol}</Box>
						<Box sx={{ flex: 1 }}>{row.contractId}</Box>
						<Box sx={{ flex: 1 }}>{row.type}</Box>
						<Box sx={{ flex: 1 }}>{row.amount}</Box>
						<Box sx={{ flex: 1 }}>
							<Typography sx={{ color: row.profitColor }}>{row.profit}</Typography>
						</Box>
						<Box sx={{ flex: 1 }}>{row.status}</Box>
					</Box>
				))}
			</Box>

			{/* Fila de Totales */}
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					backgroundColor: "rgba(113, 121, 126, 0.7)",
					padding: "10px 15px",
					borderRadius: "8px",
					marginTop: "16px",
					color: "#fff",
					fontWeight: "bold",
					position: "sticky",
					bottom: 0,
					zIndex: 1,
				}}>
				<Box sx={{ flex: 1, fontSize: 16, fontWeight: "bold" }}>Ganancias</Box>
				<Box sx={{ flex: 1, fontSize: 16, fontWeight: "bold" }} style={{ color: "#4599d9" }}>
					{totals.totalGain}
				</Box>
				<Box sx={{ flex: 1, fontSize: 16, fontWeight: "bold" }}>Pérdidas</Box>
				<Box sx={{ flex: 1, fontSize: 16, fontWeight: "bold" }} style={{ color: "#f74712" }}>
					{totals.totalLoss}
				</Box>
				<Box sx={{ flex: 1, fontSize: 16, fontWeight: "bold" }}>Total</Box>
				<Box sx={{ flex: 1, fontSize: 16, fontWeight: "bold", color: totals.profit >= 0 ? "#4599d9" : "#f74712" }}>{totals.profit}</Box>
				<Box sx={{ flex: 1, fontSize: 16, fontWeight: "bold" }}>
					<span style={{ color: "#4599d9" }}>{`Ganadas: ${totals.wins}`}</span>
					{"  |  "}
					<span style={{ color: "#f74712" }}>{` Perdidas: ${totals.losses}`}</span>
				</Box>
			</Box>
		</Box>
	);
}
